@import './scss/_variables.scss';
@import '~bootstrap/scss/bootstrap.scss';
@import './scss/_smartbip.scss';
@import './scss/_brand.scss';

.leaflet-frame {
    overscroll-behavior: none;
    padding: 30px 50px;
    @media screen and (max-width: "991px") {
      padding: 30px 20px;
    }
    .go-back-button {
      margin-left: 0;
    }
    .framePdf {
      display: flex;
      align-items: center;
      iframe {
        height: calc(100vh - 145px);
        width: 100vw;
      }
    }
  }