@import './scss/_variables.scss';

// react-toastify
@import '../node_modules/react-toastify/dist/ReactToastify.css';

.brand-primary-text {
    color: $brand-secondary;
    //font-weight: bold;
    text-transform: uppercase;
}

.flip-box {
    background-color: transparent;
    height: auto;
    perspective: 1000px;
}
  
.flip-box-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

.flip-box:hover .flip-box-inner {
    transform: rotateY(180deg);
}

.flip-box-front, .flip-box-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}

.flip-box-back {
    transform: rotateY(180deg);
}

@media (max-width: 576px) {
    .barcode {
       display: none;
    }
}

.leaflet-image {
    max-height: 240px;
    width: auto;
    min-width: 125px;
    max-width: 200px;
    position: relative;
    overflow: hidden;
    border: 3px solid #fff;
    box-shadow: 0 8px 8px 0 rgba(0,0,0,0.2);
    cursor: pointer;
    transition: 0.3s;
}

.leaflet-image:hover {
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.2);
}

.leaflet-image img {
    max-width: 100%;
    width: 100%;
    height: auto;
    transition: transform .5s;
}

.thumbs-wrapper .control-arrow {
    background: $brand-primary!important;
}

.carousel-slider .control-arrow {
    opacity: unset!important;
    background: rgba(0,0,0,.2)!important;
}

.carousel-control-next-icon, .carousel-control-prev-icon {
    width: 50px!important;
    height: 50px!important;
}

.signature {
    width: 100%;
    height: 400px;
    top: 10%;
    left: 10%;
}

.sigContainer {
    border: 1px solid #333;
    width: 100%;
    height: 400px;
    margin: 0 auto;
    background-color: #fff;
}
  
.sigPad {
    width: 100%;
    height: 100%;
}

// body, div#root, .side-menu {
//     min-height: 100%;
// }

.side-menu {
    background-color: $brand-menu-bg;
    padding-left: 0;
    padding-right: 0;
    border-right: 1px solid darken($brand-menu-bg, 15%);
    min-height: 570px;

    .logo {
        padding-right: 10px;
    }

    .menu-item {
        text-align: center;
        text-transform: uppercase;
        min-height: 50px;
        padding: 20px 0;
        border-bottom: 1px solid darken($brand-menu-bg, 15%);
        color: $body-color;

        &.active {
            background-color: $brand-primary;
            color: #fff;
        }
    }
}

@media (min-width: 576px) {
    .content, .side-menu {
        padding-top: $margin-top;
    }
}

.footer {    
    background-color: #fff;
    color: inherit;
}

h1, h2, h3, h4 {
    color: $brand-titles;
}

p {
    color: black;
}

.input-required {
    background-color: #fff3cf;
}

.popup {
    font-size: 12px;
}

.popup .header {
    width: 100%;
    border-bottom: 1px solid #c1c1c1;
    font-size: 18px;
    text-align: center;
    padding: 5px;
    text-transform: uppercase;
}
.popup .content {
    font-size: 16px;
    width: 100%;
    padding: 10px 5px;
}
.popup .actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
}

.popup .button {
    background: $brand-primary;
    border: 0;
    color: #fff;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 12px;
    height: 50px;
    line-height: 50px;
    margin-right: 10px;
    width: 20%;
}

.popup .abort {
    background: #c1c1c1;
}

.popup .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
}

.go-back-button {
    background: transparent;
    border: 0;
    color: $brand-primary;
    cursor: pointer;
    font-size: 18px;
}

.fullscreen-enabled {
    background-color: #fff;
    overflow: auto;
}

.popup-content {
    border-radius: 10px;
}

.form-fields-box {
    border: 2px solid #999;
    border-radius: 10px;
    padding: 7px 7px;
}

.custom-control-label {
    position: initial;
}

.custom-control {
    padding-left: 0.5rem;
}

.custom-control-label::before, 
.custom-control-label::after {
    top: .8rem;
    width: 1.25rem;
    height: 1.25rem;
}

// react-datepicker
@import "../node_modules/react-datepicker/dist/react-datepicker.css";
@import "../node_modules/react-datepicker/dist/react-datepicker-cssmodules.css";

.smartbip-datepicker {
    font-family: FuturaStd-Book;
    font-size: .8rem;
    background-color: #fff;
    position: relative;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected, 
.react-datepicker__day--in-selecting-range, 
.react-datepicker__day--in-range, 
.react-datepicker__close-icon::after {
    background-color: $brand-primary!important;
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__year-dropdown--scrollable {
    height: 450px!important;
}

.react-datepicker__month-option,
.react-datepicker__year-option {
    line-height: 2!important;
    font-size: 18px;
}

.sign-up-choice {
    button {
        min-width: 370px;
        text-transform: uppercase
        
    }
    @media screen and (max-width: 700px) {
        button {
            min-width: 300px;
            text-transform: uppercase
        }
    }
}

.sign-up-link {
    @media screen and (max-width: 700px) {
        margin-top: 30px;
        margin-bottom: 30px;
      }
}