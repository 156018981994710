$footer-height: 80px;
$default-margin: 20px;

// * {
    // make the text not selectable
    // -webkit-touch-callout: none;
    // -webkit-user-select: none;
    // -khtml-user-select: none;
    // -moz-user-select: none;
    // -ms-user-select: none;
    // user-select: none;
// }

// attach footer to bottom
html {
    position: relative;
    min-height: 100%;
}

body {
    margin-bottom: $footer-height + $default-margin; // 10 px leave a botton margin under the content
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: $footer-height; /* Set the fixed height of the footer here */
    padding-top: 10px;
}

.footer {
    font-size: 80%;
    background-color: #333;
    color: #fff;
}

.navbar {
    margin-bottom: $default-margin;
}

.form-control-invalid {
    width: 100%;
    margin-top: .25rem;
    color: $danger;
    font-size: 80%;
}

// To be defined style
.tbd {
    background-color: yellow;
}

.leaflet-container {
    z-index: 1; 
    height: 325px;
}