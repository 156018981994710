// Brand

$brand-primary: rgb(0, 105, 55);
$brand-secondary: rgb(225, 37, 27);
$brand-titles: rgb(206, 14, 45);
$brand-menu-bg: rgb(242, 242, 242);

@import url('https://fonts.googleapis.com/css?family=Montserrat:400,400i,700&display=swap');
$font-family-sans-serif: 'Montserrat', sans-serif;
$font-size-base: 0.9rem;

$margin-top: 40px;

// Overrides

$primary: $brand-primary;
