.delete{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 26px;
    .title{
        font-family: FuturaStd-Bold;;
        font-size: 16px;
        font-weight: bold;
    }
    .subtitle{
        font-family: FuturaStd-Book;
        font-size: 16px;
    }
    .image-container{
        display: flex;
        gap: 32px;
        flex-wrap: wrap;
        width: 100%;
        img{
            width: 242px;
            height: 500px;
        }
    }
}